<script setup lang="ts">
    export interface FooterMenu {
        id: string;
        title: string;
        items?: FooterMenu[] | null;
        link?: Link;
        color?: string | null;
    }

    interface FooterMenuProps {
        item: FooterMenu;
        index: number;
        expanded?: boolean;
    }

    defineProps<FooterMenuProps>();

    const expanded = ref(-1);
    const { generateStringOnlyURL } = useURL();

    const mobileExpanded = (index: number) => {
        expanded.value = expanded.value === index ? -1 : index;
    };
</script>

<template>
    <div class="atm-footer-menu">
        <div :class="['flex flex-col max-md:border-b max-md:border-woom-grey-mid md:gap-4', { 'max-md:border-t': index === 0 }]">
            <atm-heading
                level="h4"
                size="xs">
                <nuxt-link
                    v-if="item.link"
                    :to="generateStringOnlyURL(item.link)"
                    class="hidden font-bold transition-colors md:block [&[href]]:hover:text-woom-red [&[href]]:hover:underline">
                    {{ item.title }}
                </nuxt-link>
                <span
                    v-else
                    class="hidden font-bold md:block">
                    {{ item.title }}
                </span>

                <button
                    class="flex w-full items-center justify-between py-4 md:hidden"
                    @click="mobileExpanded(index)">
                    <span class="font-bold">{{ item.title }}</span>

                    <span class="relative h-5 w-5">
                        <transition
                            enter-from-class="opacity-0"
                            enter-active-class="duration-200 ease-in"
                            enter-to-class="opacity-100"
                            leave-from-class="opacity-100"
                            leave-active-class="duration-200 ease-in"
                            leave-to-class="opacity-0">
                            <woom-icon-plus
                                class="absolute h-full w-full"
                                v-if="index !== expanded" />
                            <woom-icon-minus
                                class="absolute h-full w-full"
                                v-else-if="index === expanded" />
                        </transition>
                    </span>
                </button>
            </atm-heading>

            <div
                v-if="item.items"
                :class="['footer-menu-items grid md:grid-rows-[1fr]', index === expanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]']">
                <ul class="overflow-hidden">
                    <template
                        v-for="footerMenu in item.items"
                        :key="footerMenu.id">
                        <li
                            v-if="footerMenu?.link"
                            class="last:max-md:mb-3">
                            <nuxt-link
                                :to="generateStringOnlyURL(footerMenu?.link)"
                                class="inline-flex py-1 transition-colors [&[href]]:hover:text-woom-red [&[href]]:hover:underline">
                                {{ footerMenu.title }}
                            </nuxt-link>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .footer-menu-items {
        transition: grid-template-rows 300ms ease-out;
    }
</style>
